<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.2263 2.77384V1.00269L5.20632 3.95373L8.2263 6.90477V5.13361C10.1804 5.13361 11.7686 6.71917 11.7686 8.67593C11.7686 10.6327 10.1831 12.2182 8.2263 12.2182C6.2722 12.2182 4.68399 10.6327 4.68399 8.67593H2.32422C2.32422 11.9345 4.9677 14.578 8.2263 14.578C11.4876 14.578 14.1284 11.9345 14.1284 8.67593C14.1284 5.41467 11.4876 2.77384 8.2263 2.77384V2.77384Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowRotateLeft'
}
</script>
